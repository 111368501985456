import React, { useEffect, useState } from "react";
import PageContainer from "../../components/layouts/PageContainer";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import HeaderMobile from "../../components/shared/headers/HeaderMobile";
import FooterMarketPlace2 from "../../components/shared/footers/FooterMarketPlace2";
import BreadCrumb from "../../components/elements/BreadCrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import config from "../../config";
import axios from "axios";
import Loader from "../Loader";
import { Spin } from "antd";

const PaymentSuccess = () => {
  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  );
  const footer = <FooterMarketPlace2 />;
  const [loading, setLoading] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const sessionIdFromUrl = queryParams.get("session_id");

    const onGetStatus = async () => {
      setLoading(true);
      try {
        await axios({
          method: "get",
          url: `${config.apiUrl}/getSessionData/${sessionIdFromUrl}`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then((res) => {
          if (res?.data == true) {
            localStorage.removeItem("order_id");
            localStorage.removeItem("ship");
            localStorage.removeItem("items");
            localStorage.removeItem("auth_users");
            localStorage.removeItem("congestion");
            setLoading(false);
          } else {
            setLoading(false);
            navigate(`${config.appUrl}failed`);
          }
        });
      } catch (error) {
        console.log(error);
        navigate(`${config.appUrl}failed`);
        setLoading(false);
      }
    };
    onGetStatus();
  }, []);

  return (
    <div>
      {loading && <Loader />}
      <PageContainer header={headers} footer={footer} title="Register">
        <div className="ps-page--single">
          <div className="ps-section--custom pt-2">
            <div className="d-flex justify-content-center">
              <div className="col-7 ">
                {loading === false ? (
                  <div className="text-center">
                    <img
                      src="https://img.freepik.com/free-vector/flat-woman-paying-by-pos-terminal-refund-cashback_88138-785.jpg?size=626&ext=jpg&ga=GA1.1.535303181.1722840199&semt=ais_hybrid"
                      alt="No img"
                      width="550"
                      className="margin-auto"
                    />
                    <h1>Hurrah Payment Succeeded !</h1>
                    <p className="text-black">
                      Thank you for your purchase! Your order  has been successfully
                      placed and is being processed. You will receive a
                      confirmation email shortly with the details of your order.
                    </p>
                    <Link to={`${config.appUrl}`} className="btn-blue mr-20">
                      Home
                    </Link>
                  </div>
                ) : (
                  <div className="mt-5 mb-5 text-center">
                    <div className="mt-3 mb-3">
                      <Spin />
                    </div>
                    <h3>Wait a moment</h3>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default PaymentSuccess;
