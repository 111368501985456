import React, { useEffect } from "react"

const TawkToChat = ({ handleClick }) => {
  useEffect(() => {
    const tawkToScript = document.createElement("script")
    tawkToScript.src =
      // "https://embed.tawk.to/660e9ae6a0c6737bd1285943/1hqkgq5sf"   //test
       "https://embed.tawk.to/66307dd4a0c6737bd1327d82/1hsmmn24o"      // Live
    tawkToScript.async = true
    document.body.appendChild(tawkToScript)

    return () => {
      document.body.removeChild(tawkToScript)
    }
  }, [])

  return <div>{/* This div will be replaced by the Tawk.to chat widget */}</div>
}

export default TawkToChat
