import React, { useEffect, useState } from "react";
import PageContainer from "../../components/layouts/PageContainer";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import HeaderMobile from "../../components/shared/headers/HeaderMobile";
import FooterMarketPlace2 from "../../components/shared/footers/FooterMarketPlace2";
import { Link, useNavigate } from "react-router-dom";
import config from "../../config";

const PaymentCancel = () => {
  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  );
  const footer = <FooterMarketPlace2 />;


  return (
    <div>
      <PageContainer header={headers} footer={footer} title="Register">
        <div className="ps-page--single">
          <div className="ps-section--custom pt-2">
            <div className="d-flex justify-content-center">
              <div className="col-7 ">
                <div className="text-center">
                  <img
                    src="https://img.freepik.com/premium-vector/vector-illustration-about-concept-payment-error-nfc-non-cash-payment-transaction-cancelled_675567-3923.jpg?ga=GA1.1.535303181.1722840199&semt=ais_hybrid"
                    alt="No img"
                    width="550"
                    className="margin-auto"
                  />
                  <h1>OOPS Payment Failed !</h1>
                  <p className="text-black">
                  We're sorry, but your payment could not be processed at this time. Please check your payment details and try again, or use a different payment method. If the problem persists, contact our support team for assistance
                  </p>
                  <Link to={`${config.appUrl}`} className="btn-blue mr-20">Home</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default PaymentCancel;
